import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavButton = _resolveComponent("NavButton")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_NavButton, {
      label: "About Me",
      link: "/",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Research",
      link: "/research",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Teaching",
      link: "/teaching",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Talks",
      link: "/talks",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Education",
      link: "/education",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "Code",
      link: "/code",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"]),
    _createVNode(_component_NavButton, {
      label: "CV",
      link: "https://github.com/SachaGoldman/CV/raw/master/CV.pdf",
      "use-a": "",
      onClickPassive: _ctx.onNavButtonClick
    }, null, 8, ["onClickPassive"])
  ]))
}