import { nextTick } from "vue";
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "About",
        meta: {
            title: "Sacha Goldman | About",
        },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/research",
        name: "Research",
        meta: {
            title: "Sacha Goldman | Research",
        },
        component: () =>
            import(/* webpackChunkName: "research" */ "../views/Research.vue"),
    },
    {
        path: "/education",
        name: "Education",
        meta: {
            title: "Sacha Goldman | Education",
        },
        component: () =>
            import(
                /* webpackChunkName: "education" */ "../views/Education.vue"
            ),
    },
    {
        path: "/teaching",
        name: "Teaching",
        meta: {
            title: "Sacha Goldman | Teaching",
        },
        component: () =>
            import(/* webpackChunkName: "teaching" */ "../views/Teaching.vue"),
    },
    {
        path: "/talks",
        name: "Talks",
        meta: {
            title: "Sacha Goldman | Talks",
        },
        component: () =>
            import(/* webpackChunkName: "talks" */ "../views/Talks.vue"),
    },
    {
        path: "/code",
        name: "Code",
        meta: {
            title: "Sacha Goldman | Code",
        },
        component: () =>
            import(/* webpackChunkName: "code" */ "../views/Code.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    nextTick(() => {
        if (to.meta.title) document.title = to.meta.title as string;
    });
});

export default router;
